import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)

/**
 * 时间格式化
 * @param {*} date 需要格式化的日期或者时间戳
 * @param {*} needTime 是否需要显示具体时间
 * @param {*} yearNeedTime 以往年份是否需要显示具体时间
 * @param {*} format 日期格式
 * @returns 格式化后日期、时间
 */
function cusFormatDate(date, needTime = true, yearNeedTime = false, format = 'YYYY-MM-DD') {
  if([null, '', undefined, 0].includes(date)) { return '-'}
  if(typeof date == 'string'){
    date = parseFloat(date)
  }
  // 确保date是时间戳
  date = dayjs(date).valueOf()

  // 格式化
  if (dayjs(date).isToday()) {
    return  needTime ? '今天 ' + dayjs(date).format('HH:mm') : '今天'
  } else if (dayjs(date).isTomorrow()) {
    return needTime ? '明天 ' + dayjs(date).format('HH:mm') : '明天'
  } else if (dayjs(date).isYesterday()) {
    return needTime ? '昨天 ' + dayjs(date).format('HH:mm') : '昨天'
  } else if (dayjs().isSame(dayjs(date), 'year')) {
    return needTime ? dayjs(date).format('MM-DD HH:mm') : dayjs(date).format('MM-DD')
  } else {
    return yearNeedTime ? dayjs(date).format(`${format} HH:mm`) : dayjs(date).format(format)
  }
}

export { cusFormatDate }