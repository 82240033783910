import { ref } from 'vue'
import { Message } from '@arco-design/web-vue'
import uuid from '@/utils/uuid'
import { useUploadFileList } from '@/store/useUploadFileList'
import { fileAddFolder, sendMessage } from '@/services/investment/document'
import { useLarkUpload } from './useLarkUpload'

export type FileState = 'prepare' | 'uploading' | 'success' | 'error' | 'cancel'
// type UploadFile = LocalFile & { uuid: string; state: FileState; progress?: number; file?: any }
type TreeNode = { name: string; file: LocalFile; children?: TreeNode[]; folderId?: string }

const createFolder = (name: string, dataId: string, parentId: string) => {
  return fileAddFolder({ data: { dataId, fileName: name, parentId } })
}

export const useUploader = (sourceKey?: string, tenantId?: string) => {
  const { uploadLargeFile, abortFileUpload } = useLarkUpload(tenantId)

  const preUploadFileList = ref<
    { file: UploadFile; formData: Record<string, any>; headers: Record<string, any> }[]
  >([])
  const isUploading = ref(false)
  const uploadFileListStore = useUploadFileList()
  let xhr: XMLHttpRequest

  const uploadSingleFile = (
    file: UploadFile,
    formData: Record<string, any>,
    headers: Record<string, any>,
    transformLocalFile: boolean, // 是否需要转换本地文件为云文档
    // requestUrl: string, // 文件上传地址
  ) => {
    const targetFile = file
    // const _uuid = uuid()
    // const targetFile: UploadFile = {
    //   ...file,
    //   uuid: _uuid,
    //   state: 'prepare',
    //   progress: 0,
    // }
    // uploadFileListStore.addFile(targetFile, sourceKey)

    return new Promise<{ fileName: string; fileToken: string; fileType: string }>(
      (resolve, reject) => {
        xhr = new XMLHttpRequest()

        xhr.upload.onprogress = (e) => {
          const percent = e.total > 0 ? Number((e.loaded / e.total).toFixed(2)) : 0
          uploadFileListStore.updateFileProgress(targetFile, percent === 1 ? 0.99 : percent)
        }
        xhr.onerror = function error(e) {
          uploadFileListStore.updateFileState(targetFile, 'error')
          // this.onError(e, file);
          // resolve();
          const file = uploadFileListStore.getFileByUuid(targetFile.uuid)

          reject(file)
        }
        xhr.onload = () => {
          const file = uploadFileListStore.getFileByUuid(targetFile.uuid)
          if (xhr.status < 200 || xhr.status >= 300) {
            uploadFileListStore.updateFileState(targetFile, 'error')
            reject(file)
            // this.onError(this.getResponse(xhr), file);
            // resolve();
            return
          }
          const res = JSON.parse(xhr.response)
          const data = res.data
          if (res.code !== 0) {
            uploadFileListStore.updateFileState(targetFile, 'error')
            const file = uploadFileListStore.getFileByUuid(targetFile.uuid)
            return reject(file)
          }

          uploadFileListStore.updateFileState(targetFile, 'success')
          uploadFileListStore.updateFileByUuid(targetFile.uuid, data)
          resolve({
            ...targetFile,
            ...data,
          })
          // this.onSuccess(this.getResponse(xhr), file);
          // resolve();
        }
        xhr.onabort = () => {
          isUploading.value = false
          const file = uploadFileListStore.getFileByUuid(targetFile.uuid)

          reject(file)
        }

        const fileFormData = new FormData()

        if (file.file) {
          fileFormData.append('file', file.file)
          fileFormData.append('fileName', file.file.name)
        }
        for (const key of Object.keys(formData)) {
          const v = formData[key] ?? null
          if (v !== null) {
            fileFormData.append(key, v)
          }
        }
        // let url = requestUrl || '/api/file/uploadAllFile/'
        let url = '/api/file/uploadAllFile/'
        xhr.open('post', url, true)

        for (const key of Object.keys(headers)) {
          xhr.setRequestHeader(key, headers[key])
        }

        uploadFileListStore.updateFileState(targetFile, 'uploading')

        xhr.send(fileFormData)
      },
    )
  }

  const appendMultipleFileUpload = async (
    files: any[],
    formData: Record<string, any>,
    headers: Record<string, any>,
    cb: (
      res: { fileName: string; fileToken: string; fileType: string },
      file: UploadFile,
      parentId?: string,
    ) => Promise<any>,
    sourceKey?: string,
    // requestUrl?: string,
    withoutLogin?: boolean,
  ) => {
    const composedFiles = files.map((file) => {
      return {
        file: {
          ...file,
          fileName: file.name,
          uuid: file?.uuid ?? uuid(),
          state: 'prepare',
          progress: 0,
          file,
          businessCode: formData.businessCode,
          dataId: formData.dataId,
          parentId: formData.parentId,
          isFolderFile: formData.isFolderFile ?? false,
          tmpFolderId: formData.tmpFolderId ?? null,
          rootFolderName: formData.rootFolderName ?? null,
        },
        formData,
        headers,
      }
    })

    preUploadFileList.value = preUploadFileList.value.concat(composedFiles)
    composedFiles.forEach(({ file }) => {
      uploadFileListStore.addFile(file, sourceKey)
    })

    doUpload(
      // formData,
      // headers,
      formData?.transformLocalFile,
      // requestUrl,
      withoutLogin, // 是否需要免登录
      cb,
    )
  }

  const doUpload = async (
    // formData: Record<string, any>,
    // headers: Record<string, any>,
    transformLocalFile: boolean,
    // requestUrl: string,
    withoutLogin: boolean, // 是否需要免登录
    cb: (
      res: { fileName: string; fileToken: string; fileType: string; source: FileSource; error?: any },
      file: UploadFile,
      parentId?: string,
    ) => Promise<any>,
  ) => {
    if (isUploading.value) return

    while (preUploadFileList.value.length) {
      isUploading.value = true
      const preUploadFile: any = preUploadFileList.value[0]
      const { file, formData, headers } = preUploadFile


      // const { size = 0 } = file?.file
      const isLargeFile = true // size > 20 * 1024 * 1024 // 原本设计大文件才从飞书，现改成全部走飞书

      let uploadFn = isLargeFile ? uploadLargeFile : uploadSingleFile
      
      // if (withoutLogin) {
      //   uploadFn = uploadSingleFile
      // }

      try {
        const res = await uploadFn(file, formData, headers, transformLocalFile)
        // 检查文件是否被取消
        if (file.state === 'cancel') {
          preUploadFileList.value.shift()
          continue
        }
        
        const isFileExisted = preUploadFileList.value[0] === preUploadFile

        if (!isFileExisted) {
          throw new Error(`${file.fileName}文件已取消`)
        }

        await cb({
          ...res,
          source: 'upload',
        }, file, file.parentId)
        
        preUploadFileList.value.shift()
        // folderUploadCounts[file.tmpFolderId] += 1
        checkFolderUploadEnded(file.tmpFolderId, formData.dataId, file.rootFolderName)
      } catch (error: any) {
        isUploading.value = false
        // 如果是取消操作，不显示错误信息
        if (file.state === 'cancel') {
          preUploadFileList.value.shift()
          continue
        }
        // 检查错误类型
        let errorType = 'unknown';
        if (error.message && (error.message.includes('timeout') || error.code === 'ECONNABORTED')) {
          errorType = 'timeout';
          file.errorType = 'timeout';
        } else if (error.message && error.message.includes('size') || 
                  (error.response && error.response.data && 
                  error.response.data.message && error.response.data.message.includes('大小'))) {
          errorType = 'oversize';
          file.errorType = 'oversize';
        }
        
        // 更新文件状态
        uploadFileListStore.updateFileState(file, 'error')
        
        // 调用回调函数，传递错误信息
        await cb({
          fileName: file.fileName,
          fileToken: '',
          fileType: '',
          source: 'upload',
          error: {
            type: errorType,
            originalError: error
          }
        }, file, file.parentId).catch(() => {
          // 忽略回调中的错误
        })
        
        if (file?.state == 'error') {
          // 不要清除文件列表，让用户可以看到错误状态
          // clearFileList(file.uuid)
        }

        file.fileName && file.state === 'error' && 
          Message.error(`${file.fileName}上传失败${errorType === 'timeout' ? '(超时)' : errorType === 'oversize' ? '(文件过大)' : ''}`)
        
        // 继续处理下一个文件
        preUploadFileList.value.shift()
      }
    }

    isUploading.value = false
  }

  // 判断是否为文件夹最后一个文件
  const checkFolderUploadEnded = (tmpFolderId: string, dataId: string, folderName: string) => {
    if (tmpFolderId && dataId) {
      const folderFileList = uploadFileListStore.filterFileByCondition((file) => {
        return Boolean(file.isFolderFile && file.tmpFolderId === tmpFolderId)
      })
      const isLastFile = folderFileList.length === 0

      if (isLastFile) {
        sendMessage(dataId, folderName)
      }
    }
  }

  const reuploadFile = (
    file: UploadFile,
    formData: Record<string, any>,
    headers: Record<string, any>,
    cb: (
      res: { fileName: string; fileToken: string; fileType: string },
      file: UploadFile,
      parentId?: string,
    ) => Promise<any>,
    sourceKey?: string,
    // requestUrl?: string,
    withoutLogin?: boolean
  ) => {
    uploadFileListStore.removeFile(file)
    uploadFileListStore.addFile(file, sourceKey)
    const data = preUploadFileList.value.filter((f) => f.file.uuid !== file.uuid)
    preUploadFileList.value = [...data, { file, formData, headers }]

    doUpload(
      // formData,
      // headers,
      formData?.transformLocalFile,
      // requestUrl,
      withoutLogin, // 是否需要免登录
      cb,
    )
  }

  const clearFileList = async (sourceKey: string) => {
    const list = uploadFileListStore.filterFileByCondition((file) => {
      return file.sourceKey === sourceKey && file.state === 'uploading'
    })
    if (isUploading.value && list.length > 0) {
      // 中止所有正在上传的文件
      await Promise.all(list.map(file => abortFileUpload(file)))
    }
    // 只移除指定的文件
    uploadFileListStore.removeFile(list[0])
    // uploadFileListStore.clearFileStore(sourceKey)
    // preUploadFileList.value = []
  }

  const folderUploadCounts: Record<string, number> = {}

  const uploadFileFolder = (
    files: File[],
    formData: Record<string, any>,
    headers: Record<string, any>,
    cb: (
      res: { fileName: string; fileToken: string; fileType: string },
      file: UploadFile,
      parentId?: string,
    ) => Promise<any>,
    sourceKey?: string,
  ) => {
    const result: TreeNode[] = []
    const level: Record<string, any> = { result }
    const folderId = uuid()
    let folderName = ''
    folderUploadCounts[folderId] = 0

    files.forEach((file) => {
      const path = file.webkitRelativePath
      const paths = path.split('/')
      folderName = paths[0] ?? ''

      paths.reduce((r, name, i) => {
        const isExisted = r[name]
        const isFolder = i !== paths.length - 1

        if (!isExisted) {
          r[name] = { result: isFolder ? [] : null }
          r.result.push({ name, file, children: r[name].result })
        }

        return r[name]
      }, level)
    })

    const rs = createFolderBeforeUploadFile(
      result,
      formData,
      headers,
      cb,
      sourceKey,
      folderId,
      folderName,
    )
  }

  const createFolderBeforeUploadFile = async (
    tree: TreeNode[],
    formData: Record<string, any>,
    headers: Record<string, any>,
    cb: (
      res: { fileName: string; fileToken: string; fileType: string },
      file: UploadFile,
      parentId?: string,
    ) => Promise<any>,
    sourceKey?: string,
    tmpFolderId?: string,
    rootFolderName?: string,
  ) => {
    const { parentId = '-1', dataId } = formData

    for (let node of tree) {
      const isFolder = node.children

      if (isFolder) {
        const folderId = (await createFolder(node.name, dataId, parentId)) as string

        node.folderId = folderId

        await createFolderBeforeUploadFile(
          node.children!,
          { ...formData, parentId: folderId },
          headers,
          cb,
          sourceKey,
          tmpFolderId,
          rootFolderName,
        )
      } else {
        appendMultipleFileUpload(
          [node.file],
          { ...formData, isFolderFile: true, tmpFolderId, rootFolderName },
          headers,
          cb,
          sourceKey,
        )
      }
    }

    return tree
  }

  return {
    uploadFileFolder,
    uploadSingleFile,
    appendMultipleFileUpload,
    clearFileList,
    removeFile: (file: UploadFile) => {
      uploadFileListStore.removeFile(file)
    },
    reuploadFile,
    abortFileUpload,
  }
}
