import TurndownService from 'turndown';

const option = {
  headingStyle: 'atx',
  hr: '<hr>',
  bulletListMarker: ' -',
  codeBlockStyle: 'fenced',
  fence: '```',
  emDelimiter: '_',
  strongDelimiter: '**',
  linkStyle: 'inlined',
  linkReferenceStyle: 'full',
  preformattedCode: false,
};

// 处理html转为Markdown
export function htmlToMarkdown (htmlString){
  const turndownService = new TurndownService(option);

  // 自定义规则处理嵌套列表
  turndownService.addRule('nestedList', {
    filter: function (node, options) {
        return node.nodeName === 'UL' || node.nodeName === 'OL';
    },
    replacement: function (content, node, options) {
        let parent = node.parentNode;
        if (parent && parent.nodeName === 'LI') {
            // 为嵌套列表的每一行添加两个空格
            content = content.split('\n').map(line => {
                if (line.trim()!== '') {
                    return '  '+ line;
                }
                return line;
            }).join('\n');
        }
        return (node.nodeName === 'UL'? '\n' : '') +
            content.replace(/^\n+|\n+$/g, '') +
            (node.nodeName === 'UL'? '\n' : '\n\n');
    }
  });
  // 自定义规则处理表格
  turndownService.addRule('table', {
    filter: 'table',
    replacement: function (content, node) {
        const rows = Array.from(node.rows);
        const headers = Array.from(rows[0].cells).map(cell => cell.textContent.trim());
        const separator = headers.map(() => '--------').join(' | ');
        const dataRows = rows.slice(1).map(row => {
            return Array.from(row.cells).map(cell => cell.textContent.trim()).join(' | ');
        });
        const table = [
            '|' + headers.join(' | ') + '|',
            '|' + separator + '|',
            ...dataRows.map(row => '|' + row + '|')
        ].join('\n');
        return table + '\n\n';
    }
  });

  // // 自定义规则处理标题后的 **
  // turndownService.addRule('removeBoldFromHeadings', {
  //   filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  //   replacement: function (content, node, options) {
  //       const level = parseInt(node.nodeName.charAt(1), 10);
  //       const heading = '#'.repeat(level);
  //       content = content.replace(/^\*\*/, '').replace(/\*\*$/, '');
  //       return `${heading} ${content}\n\n`;
  //   }
  // });

  const markdwonString = turndownService.turndown(htmlString)
  return markdwonString
}

// 主要处理ai返回的Markdown文本，将其转换为wangeditor的html格式
export function adjustHTMLNestingLevel (htmlString){
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const lis = doc.querySelectorAll('li');

  // 处理li标签中包裹的ul或者ol标签，把ul或者ol放到上一个li的后面，而不是嵌套在里面（wangeditor要求的格式）
  lis.forEach(li => {
    const nestedLists = li.querySelectorAll('ul, ol');
    nestedLists.forEach(nestedList => {
      const parentLi = nestedList.parentNode;
      const parentUl = parentLi.parentNode;
      const index = Array.from(parentUl.children).indexOf(parentLi);
      parentUl.insertBefore(nestedList, parentLi.nextSibling);
    });

    // 将 li 标签中嵌套的 table 标签及其内容的层级提升到与 li 标签同级
    const tables = li.querySelectorAll('table');
    tables.forEach(table => {
      const parentLi = table.parentNode;
      const parentUl = parentLi.parentNode;
      const index = Array.from(parentUl.children).indexOf(parentLi);
      parentUl.insertBefore(table, parentLi.nextSibling);
    });

    // 当 li 标签中的第一个标签是 p 标签，且 p 标签中的第一个标签是 strong 标签时，保留 p 标签内除 strong 外的内容
    const firstChild = li.firstElementChild;
    if (firstChild && firstChild.nodeName === 'P') {
      const firstGrandChild = firstChild.firstElementChild;
      if (firstGrandChild && firstGrandChild.nodeName === 'STRONG') {
        const parent = li.parentNode;
        // 把 strong 标签移到 li 标签内
        li.insertBefore(firstGrandChild, firstChild);
        // 保留 p 标签内除 strong 外的内容
        while (firstChild.firstChild) {
          li.insertBefore(firstChild.firstChild, null);
        }
        // 移除 p 标签
        li.removeChild(firstChild);
      }
    }
  });

  // 处理strong标签后面的：放到strong标签里面，不然会有多余换行出现（wangeditor要求的格式）
  const strongTags = doc.querySelectorAll('strong');
  strongTags.forEach(strong => {
    const nextNode = strong.nextSibling;
    if (nextNode && nextNode.nodeType === Node.TEXT_NODE && nextNode.textContent.trim() === '：') {
      strong.textContent += '：';
      nextNode.parentNode.removeChild(nextNode);
    }
  });

  // 去除 blockquote 中的 p 标签及首尾多余的 br 标签，并移除 blockquote 标签紧挨的换行符
  const blockquotes = doc.querySelectorAll('blockquote');
  blockquotes.forEach(blockquote => {
    const pTagsInBlockquote = blockquote.querySelectorAll('p');
    pTagsInBlockquote.forEach(p => {
      while (p.firstChild) {
        blockquote.insertBefore(p.firstChild, p);
      }
      blockquote.removeChild(p);
    });

    // 移除 blockquote 开头多余的 br 标签
    while (blockquote.firstChild && blockquote.firstChild.nodeName === 'BR') {
      blockquote.removeChild(blockquote.firstChild);
    }

    // 移除 blockquote 结尾多余的 br 标签
    while (blockquote.lastChild && blockquote.lastChild.nodeName === 'BR') {
      blockquote.removeChild(blockquote.lastChild);
    }

    const brTagsInBlockquote = blockquote.querySelectorAll('br');
    brTagsInBlockquote.forEach(br => {
      blockquote.removeChild(br);
    });

    // 移除 blockquote 标签紧挨的换行符
    const blockquoteOuterHTML = blockquote.outerHTML;
    const newBlockquoteOuterHTML = blockquoteOuterHTML.replace(/^\s*\n|\n\s*$/g, '');
    const newBlockquote = parser.parseFromString(newBlockquoteOuterHTML, 'text/html').body.firstChild;
    blockquote.parentNode.replaceChild(newBlockquote, blockquote);
  });

  // // 替换 p 标签中的 br 标签为 p 标签并移除 br
  // const pTags = doc.querySelectorAll('p');
  // pTags.forEach(p => {
  //   let currentNode = p.firstChild;
  //   let textContent = '';
  //   while (currentNode) {
  //     const nextNode = currentNode.nextSibling;
  //     if (currentNode.nodeName === 'BR') {
  //       if (textContent.trim()) {
  //         const newP = doc.createElement('p');
  //         newP.textContent = textContent;
  //         p.parentNode.insertBefore(newP, p.nextSibling);
  //         p = newP;
  //         textContent = '';
  //       }
  //       // 移除 br 标签
  //       currentNode.parentNode.removeChild(currentNode);
  //     } else {
  //       textContent += currentNode.textContent;
  //     }
  //     currentNode = nextNode;
  //   }
  //   if (textContent.trim()) {
  //     p.textContent = textContent;
  //   } else {
  //     p.parentNode.removeChild(p);
  //   }
  // });

  let result = doc.body.innerHTML;
  // 去除多余的换行符
  result = result.replace(/\n{2,}/g, '\n');
  result = result.replace(/<br>{2,}/g, '<br>');
  return result;
}
