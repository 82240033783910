<template>
  <div class="tool-wrapper">
    <a-popover
      v-model:popup-visible="popupVisible"
      trigger="click"
      position="bottom"
      update-at-scroll
      content-class="tool-popover"
    >
      <div class="tool-trigger" ref="toolRef">
        {{ currentActionName }}
        <Iconfont
          :size="12"
          color="#646A73"
          name="icon_d_arrow"
          :class="{
            expanded: popupVisible,
          }"
        />
      </div>
      <template #content>
        <renderToolList />
      </template>
    </a-popover>

    <renderAction />
  </div>
</template>

<script lang="tsx" setup>
import { computed, onMounted, PropType, ref , withModifiers} from 'vue'
import { debounce } from 'lodash'
import { useI18n } from 'vue-i18n'
import { Message, Select, Option, Popover, Input } from '@arco-design/web-vue'
import { isHttpUrl } from '@/utils/url'
import Iconfont from '@/components/Iconfont.vue'
import FileIcon from '@/components/fileIcon.vue'
import AutoTooltip from '@/components/AutoTooltip.vue'
import localFile from '@/assets/img/file/localfield.svg'
import cloudFile from '@/assets/img/file/feishufield.svg'
import projectFile from '@/assets/img/file/uploadProject.svg'
import doc from '@/assets/img/file/doc.svg'
import sheet from '@/assets/img/file/sheet.svg'
import bitable from '@/assets/img/file/bitable.svg'
import folder from '@/assets/img/file/folder.svg'
import mindNote from '@/assets/img/file/mindnote.svg'
import { getBigTypeByCode } from '@/utils/enums.js'
import {
  querySelfFile,
  fileQueryTree,
  getFileBusiType,
  relateDataDuplicate,
  createDoc,
  fileAddWithout,
  searchFile,
} from '@/services/investment/document.js'

const props = defineProps({
  defaultTags: {
    type: Array as PropType<string[]>,
  },
  bCode: {
    type: String as PropType<string>,
    required: true,
  },
  dataId: {
    type: String as PropType<string>,
    default: '',
  },
  // selectedType: String, //选中的businessType, 如果有传入选中的类型，则disable选项。
  // from: String, //来自，document 显示添加按钮，其他显示添加的点点点

  from: {
    type: String,
    default: '',
  },
  // needRelateData: {
  //   type: String,
  //   default: true,
  // },
  // uploadFileList: {
  //   type: Array,
  //   default: () => {
  //     return []
  //   },
  // },
  fileList: {
    type: Array as PropType<LocalFile[]>,
    default: () => {
      return []
    },
  },
  // tmpFileList: {
  //   type: Array as PropType<LocalFile[]>,
  //   default: () => [],
  // },
  typeList: {
    type: Array as PropType<FileType[]>,
    default: () => [],
  },
  updateFileTags: {
    type: Function as PropType<(file: LocalFile, tags?: string[] | undefined) => Promise<void>>,
    required: true,
  },
  tools: {
    type: Array as PropType<UploaderToolConfig['action'][]>,
    default: () => ['cloudFile', 'projectFile', 'docx', 'sheet', 'bitable'],
  },
  fieldName: { // 用于文件字段归档
    type: String as PropType<string>,
    default: '',
  },
  assetTypeId: String as PropType<string>, // 用于文件字段归档
})

const { t: i18n_t } = useI18n()
const TOOL_LIST: UploaderToolConfig[] = [
  // {
  //   icon: folder,
  //   name: '上传文件夹',
  //   action: 'fileFolder',
  // },
  // {
  //   icon: localFile,
  //   name: '上传文档',
  //   action: 'upload',
  // },
  {
    icon: cloudFile,
    name: i18n_t('plat_c.app_c.form.xuanzeyunwendang'),
    action: 'cloudFile',
    visible: () => props.tools.includes('cloudFile'),
  },
  {
    icon: projectFile,
    name: i18n_t('plat_c.app_c.form.xuanzexiangmuwendang'),
    action: 'projectFile',
    visible: () => props.tools.includes('projectFile') && !!props.dataId,
  },
  {
    icon: doc,
    name: i18n_t('plat_c.app_c.form.xinjianwendang'),
    action: 'docx',
    split: true,
    visible: () => props.tools.includes('docx'),
  },
  {
    icon: sheet,
    name: i18n_t('plat_c.app_c.form.xinjianbiaoge'),
    action: 'sheet',
    visible: () => props.tools.includes('sheet'),
  },
  {
    icon: bitable,
    name: i18n_t('plat_c.app_c.form.xinjianduoweibiaoge'),
    action: 'bitable',
    visible: () => props.tools.includes('bitable'),
  },
]

const emit = defineEmits(['update:fileList', 'uploadFileList'])

const popupVisible = ref(false)
const toolRef = ref()
const pickedFileList = ref<string[]>([])
const fileBusinessType = ref('')
const businessTypeList = ref<string[]>([])
const curActionType = ref<UploaderToolConfig['action']>(TOOL_LIST[0].action)
const cloudFileList = ref<LocalFile[]>([])
const defaultCloudFileList = ref<LocalFile[]>([])
const projectFileTree = ref<LocalFile[]>([])
// const uploadFileList = ref([])
const uploadDialogVisible = ref(false)

const curFileList = computed({
  get() {
    return props.fileList
  },
  set(v) {
    emit('update:fileList', v)
  },
})
const currentActionName = computed(() => {
  return TOOL_LIST.find((item) => {
    return item.action === curActionType.value
  })!.name
})

onMounted(() => {
  onActionClick(curActionType.value)
})

const onLocalUpload = () => {
  localFileInput.value?.click()
}
const onActionClick = (action: UploaderToolConfig['action']) => {
  curActionType.value = action
  popupVisible.value = false

  switch (action) {
    case 'fileFolder':
      break

    case 'upload':
      break

    case 'cloudFile':
      if (!defaultCloudFileList.value?.length) {
        querySelfFile(props.dataId, true).then((res: LocalFile[]) => {
          defaultCloudFileList.value = res
        })
      }
      break

    case 'projectFile':
      fetchProjectFileTree()
      break
  }
}

const fetchBusinessType = async () => {
  return await getFileBusiType({
    data: {
      dataId: props.dataId,
      dataType: getBigTypeByCode(props.bCode),
    },
  }).then((res: { typeVOList: any[]; total: number }) => {
    const typeList = res.typeVOList.map((item) => {
      return item.id
    })
    businessTypeList.value = typeList

    return typeList
  })
}

const fetchProjectFileTree = async () => {
  // const typeList = businessTypeList.value?.length
  //   ? businessTypeList.value
  //   : await fetchBusinessType()
  const typeList = props.typeList ?? []

  fileQueryTree({
    data: {
      dataId: props.dataId,
      fileBusinessType: typeList.map((type) => type.id),
      orderBy: [{ fieldName: 'fileName', isAsc: true }],
    },
  }).then((res: LocalFile[]) => {
    projectFileTree.value = res
  })
}

const onPreventEvent = (evt: Event) => {
  evt.stopPropagation()
  evt.preventDefault()
}

const fileFolderRef = ref()
const renderToolList = () => {
  return (
    <>
      {TOOL_LIST.filter((tool) => (tool.visible ? tool.visible() : true)).map((tool) => {
        return (
          <div onClick={withModifiers(onPreventEvent, ['stop']) } onPointerdown={onPreventEvent} onPointerup={onPreventEvent}>
            {tool.split && <div class="split" />}
            <div
              class={['action', curActionType.value === tool.action ? 'action--active' : '']}
              onClick={() => onActionClick(tool.action)}
            >
              <img class="action-icon" src={tool.icon} />
              <span class="action-text">{tool.name}</span>
              <Iconfont name="close-med" />
              {tool.action === 'fileFolder' && (
                <input
                  style="display: none;"
                  type="file"
                  webkitdirectory
                  ref={(el) => (fileFolderRef.value = el)}
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
const renderCurrentAction = () => {
  switch (curActionType.value) {
    case 'upload':
      return <renderLocalUpload />

    case 'cloudFile':
      return <renderCloudFile />

    case 'projectFile':
      return <renderProjectFile />

    case 'docx':
      return <renderCreateDocx />

    case 'sheet':
      return <renderCreateSheet />

    case 'bitable':
      return <renderBitable />
  }
}

const docxName = ref('')
const renderCreateDocx = () => {
  return (
    <Input
      v-model:modelValue={docxName.value}
      placeholder={i18n_t('plat_c.app_c.form.wendangmingcheng_placeholder')}
      v-slots={{
        suffix: isCreating.value ? (
          <icon-loading style="cursor: not-allowed;" />
        ) : (
          <Iconfont
            name="close-med"
            class={{
              visible: docxName.value ?? false,
            }}
            onClick={() =>
              createFileFn({
                name: docxName.value,
                type: 'docx',
              })
            }
          />
        ),
      }}
    />
  )
}

const sheetName = ref('')
const renderCreateSheet = () => {
  return (
    <Input
      placeholder={i18n_t('plat_c.app_c.form.biaogemingcheng_placeholder')}
      v-model:modelValue={sheetName.value}
      v-slots={{
        suffix: isCreating.value ? (
          <icon-loading style="cursor: not-allowed;" />
        ) : (
          <Iconfont
            name="close-med"
            class={{
              visible: sheetName.value ?? false,
            }}
            onClick={() =>
              createFileFn({
                name: sheetName.value,
                type: 'sheet',
              })
            }
          />
        ),
      }}
    />
  )
}

const bitableName = ref('')
const renderBitable = () => {
  return (
    <Input
      placeholder={i18n_t('plat_c.app_c.form.duoweibiaoge_placeholder')}
      v-model:modelValue={bitableName.value}
      v-slots={{
        suffix: isCreating.value ? (
          <icon-loading style="cursor: not-allowed;" />
        ) : (
          <Iconfont
            name="close-med"
            class={{
              visible: bitableName.value ?? false,
            }}
            onClick={() =>
              createFileFn({
                name: bitableName.value,
                type: 'bitable',
              })
            }
          />
        ),
      }}
    />
  )
}

const isCreating = ref(false)
// 新建文档,表格,多维表格
const createFileFn = ({ name, type }: { name: string; type: string }) => {
  if (isCreating.value) return
  if (['', null, undefined].includes(name)) name = i18n_t('plat_c.app_c.form.weimingmingwendang')
  isCreating.value = true

  try {
    if (
      ['followRecord', 'followRecordEdit', 'indicators', 'message', 'dialog', 'noteField', 'aiMessage'].includes(props.from)
    ) {
      fileAddWithout({
        data: {
          dataId: props.dataId,
          // fileBusinessType: '9999',
          fileTags: props.defaultTags ?? ['9999'],
          fileType: type,
          fileName: name,
        },
      })
        .then((res: any) => {
          res.type = 'userLarkDoc'
          curFileList.value = [{ ...res, source: 'create' }, ...curFileList.value]

          docxName.value = ''
          sheetName.value = ''
          bitableName.value = ''
          isCreating.value = false
          // })
        })
        .catch(() => {
          isCreating.value = false
          Message.error(i18n_t('plat_c.app_c.general_toast.xitongyichang'))
        })
      return
    }
    createDoc({
      data: {
        dataId: props.dataId,
        // fileBusinessType: '9999',
        fileTags: props.defaultTags ?? ['9999'],
        fileType: type,
        fileName: name,
        businessCode: props.bCode,
        fieldName: props.fieldName ? props.fieldName : undefined,
        assetTypeId: props.assetTypeId,
      },
    })
      .then((res: any) => {
        res.type = 'userLarkDoc'

        curFileList.value = [{ ...res, source: 'create' }, ...curFileList.value]

        docxName.value = ''
        sheetName.value = ''
        bitableName.value = ''
        isCreating.value = false
      })
      .catch(() => {
        isCreating.value = false
        Message.error(i18n_t('plat_c.app_c.general_toast.xitongyichang'))
      })
  } catch {
    isCreating.value = false
  }
}

const localFileInput = ref()
const onLocalFileChanged = (event: Event) => {
  const target = event.target as HTMLInputElement
  const files = target.files ?? []
  if (!files.length) return

  emit('uploadFileList', files)
}
const renderLocalUpload = () => (
  <div class="local-file" onClick={() => onLocalUpload()}>
    <input
      style="display: none;"
      ref={(el: any) => (localFileInput.value = el)}
      type="file"
      multiple
      onChange={onLocalFileChanged}
    />
    {i18n_t('plat_c.app_c.form.bendishangchuan_click')}
  </div>
)

const selectedCloudFileList = ref<LocalFile[]>([])
const removedFileList = ref<LocalFile[]>([])
const cloudFileKeyword = ref('')
const filteredCloudFileList = computed(() => {
  const targetList =
    cloudFileKeyword.value === '' ? defaultCloudFileList.value : cloudFileList.value

  return targetList.filter((file, idx) => {
    // 需求如此，label 没命中也展示，无力吐槽，只会抄别人需求
    return true
    const lowerCaseKeyword = cloudFileKeyword.value?.toLowerCase() ?? ''
    const lowerCaseLabel = file.fileName?.toLowerCase() ?? ''

    return lowerCaseLabel.includes(lowerCaseKeyword) && idx < 200
  })
})
const checkCloudFilePicked = (file: LocalFile) => {
  const isExisted = curFileList.value.find((f) => {
    return f.url === file.url && f.fileToken === file.fileToken
  })

  return isExisted
}
const onCloudFileClicked = async (file: LocalFile) => {
  cloudFileRef.value.blur()
  const isRemoveFile = props.fileList.find((f) => {
    return f.url === file.url && f.fileToken === file.fileToken
  })
  if (isRemoveFile) {
    const fileList = props.fileList.filter((f) => {
      return f.url !== file.url && f.fileToken !== file.fileToken
    })

    curFileList.value = fileList

    // removedFileList.value.push(isRemoveFile)
    // } else if (isExisted) {
    //   selectedCloudFileList.value = selectedCloudFileList.value.filter((item) => {
    //     return item.url !== file.url && item.fileToken !== file.fileToken
    //   })
  } else {
    // selectedCloudFileList.value.push(file)
    if (
      ['followRecord', 'followRecordEdit', 'indicators', 'message', 'dialog', 'noteField', 'aiMessage'].includes(props.from)
    ) {
      file.from = 'needRelate'
      file.type = 'messageLarkDoc'
      file.source = 'cloud'
      const defaultTags = props.defaultTags ?? ['9999']
      const originFileTags = file.fileTags ?? ['9999']
      const fileTags = [...new Set([...defaultTags, ...originFileTags])]
      file.fileTags = fileTags
      curFileList.value = [...props.fileList, ...[file]]
      return true
    }

    const originFileTags = file.fileTags ?? []
    const defaultTags = props.defaultTags ?? []
    const tmp = [...originFileTags, ...defaultTags]
    const unionTags = tmp.length ? tmp : ['9999']

    relateDataDuplicate({
      data: {
        dataId: props.dataId,
        fileTags: unionTags,
        files: [
          {
            ...file,
            fileTags: Array.from(new Set(unionTags)),
          },
        ],
        businessCode: props.bCode,
        fieldName: props.fieldName ? props.fieldName : undefined,
        assetTypeId: props.assetTypeId,
      },
    }).then((res: { existFiles: any[]; insertFiles: any[] }) => {
      const { existFiles = [], insertFiles = [] } = res
      const files = [...existFiles, ...insertFiles].map((f) => {
        return {
          ...f,
          source: 'cloud',
        }
      })
      curFileList.value = [...files, ...props.fileList]
    })
  }
}

const isPasting = ref(false)
const onCloudFilePaste = (evt: ClipboardEvent) => {
  const text = getPasteString(evt)
  const isUrl = isHttpUrl(text)

  if (isUrl) {
    evt.preventDefault()
    if (isPasting.value) return
    isPasting.value = true

    searchFile({
      data: {
        searchKey: text,
        currentPage: 1,
        pageSize: 20,
        dataId: props.dataId,
      },
    })
      .then((res: LocalFile[] = []) => {
        // const file = res.find((i) => i.url === text)
        const file = res?.length === 1 ? res[0] : null

        const isExisted =
          file &&
          props.fileList.find((f) => {
            return f.fileToken === file.fileToken
          })

        if (isExisted) {
          return Message.warning('文件已存在')
        }

        file && onCloudFileClicked(file)
      })
      .finally(() => {
        isPasting.value = false
      })
  }
}
const actionPopupVisible = ref(false)
const cloudFileRef = ref()
const renderCloudFile = () => (
  <Popover
    popupVisible={actionPopupVisible.value}
    trigger="focus"
    contentClass="cloud-file-popover"
    position="bottom"
    update-at-scroll
    unmount-on-close={false}
    auto-fit-popup-width={true}
    v-slots={{
      content: () => {
        return (
          <div
            class="cloud-file-list"
            onClick={onPreventEvent}
            onPointerdown={onPreventEvent}
            onPointerup={onPreventEvent}
          >
            {filteredCloudFileList.value.length ? (
              filteredCloudFileList.value.map((file) => {
                return (
                  <div class="cloud-file-item" onClick={() => onCloudFileClicked(file)}>
                    <FileIcon file={file} />
                    <renderHighlight
                      class="cloud-file-name"
                      text={file.fileName}
                      keyword={cloudFileKeyword.value}
                    />
                    <Iconfont
                      // class={{ visible: selectedCloudFileList.value.includes(file) }}
                      class={{ visible: checkCloudFilePicked(file) }}
                      name="close-med"
                      size={18}
                    />
                  </div>
                )
              })
            ) : (
              <div class="file-empty">{i18n_t('plat_c.app_c.form.zanwujieguo')}</div>
            )}
          </div>
        )
      },
    }}
  >
    <div class="cloud-file">
      <Input
        ref={(el: any) => (cloudFileRef.value = el)}
        v-model:modelValue={cloudFileKeyword.value}
        placeholder={i18n_t('plat_c.app_c.form.sousuoyunwendang')}
        onUpdate:modelValue={onCloudFileKeywordChanged}
        onFocus={() => (actionPopupVisible.value = true)}
        onBlur={() => (actionPopupVisible.value = false)}
        onPaste={onCloudFilePaste}
      />
    </div>
  </Popover>
)

const onCloudFileKeywordChanged = debounce((keyword: string) => {
  searchFile({
    data: {
      searchKey: keyword,
      currentPage: 1,
      pageSize: 20,
      dataId: props.dataId,
    },
  }).then((res) => {
    if (keyword === cloudFileKeyword.value) {
      cloudFileList.value = res ?? []
    }
  })
}, 500)

const projectFileKeyword = ref('')
const expandedWrappers = ref<Record<string, boolean>>({})
const onProjectFileClicked = async (file: LocalFile) => {
  cloudFileRef.value?.blur()
  const isExisted = curFileList.value.find((f) => f.id === file.id)
  file.type = 'userLocalFile'

  const defaultTags = props.defaultTags ?? ['9999']
  const originFileTags = file.fileTags ?? ['9999']
  const fileTags = [...new Set([...defaultTags, ...originFileTags])]

  if (!isExisted) {
    // 新增需要去修改 fileTags
    await props.updateFileTags(file, fileTags)
  }
  curFileList.value = isExisted
    ? curFileList.value.filter((f) => f.id !== file.id)
    : [
        {
          ...file,
          fileTags: fileTags,
          source: 'project',
        },
        ...curFileList.value,
      ]
}
const fileIdList = computed(() => {
  return props.fileList.map((file) => file.id)
})
const flatProjectFileLeafList = computed(() => {
  const flatFn = (file: LocalFile, parentLabel = '') => {
    const rs: any[] = (file.childrenList ?? []).reduce((rs: any[], childFile) => {
      const label = `${parentLabel ? parentLabel + '/' : ''}${childFile.fileName}`
      const children = childFile.childrenList?.length
        ? flatFn(childFile, label)
        : [
            {
              ...childFile,
              displayedLabel: parentLabel,
            },
          ]

      return [...rs, ...children]
    }, [])

    return rs
  }
  return projectFileTree.value.reduce((rs: any[], file: LocalFile) => {
    return [...rs, ...flatFn(file, file.fileName)]
  }, [])
})
const filteredProjectFileList = computed(() => {
  const lowerCaseKeyword = projectFileKeyword.value?.toLowerCase() ?? ''

  return flatProjectFileLeafList.value.filter((file: LocalFile & { displayedLabel: string }) => {
    const lowerCaseLabel = file.displayedLabel?.toLowerCase() ?? ''
    const lowerCaseName = file.fileName?.toLowerCase() ?? ''

    return lowerCaseLabel.includes(lowerCaseKeyword) || lowerCaseName.includes(lowerCaseKeyword)
  })
})
const onExpandFolder = (file: LocalFile) => {
  expandedWrappers.value[file.id] = !expandedWrappers.value[file.id]
}
const renderProjectFileList = (props: { fileList: LocalFile[]; level?: number }) => {
  const level = props.level ?? 0

  return (
    <div
      class="project-file-list"
      onClick={onPreventEvent}
      onPointerdown={onPreventEvent}
      onPointerup={onPreventEvent}
    >
      {props.fileList?.length ? (
        props.fileList.map((file) => {
          return (
            <>
              <div
                class={{ 'project-file-item': true, expandable: file.fileType === 'folder' }}
                style={{ paddingLeft: `${level * 20 + 8}px` }}
                onClick={() => file.fileType !== 'folder' && onProjectFileClicked(file)}
              >
                {file.fileType === 'folder' && (
                  <span class="arrow-wrapper" onClick={() => onExpandFolder(file)}>
                    <Iconfont
                      name="icon_d_arrow"
                      class={{
                        // expanded: expandedWrappers.value.includes(file.id!),
                        expanded: [undefined, true].includes(expandedWrappers.value[file.id]),
                      }}
                    />
                  </span>
                )}
                <FileIcon file={file} />
                <renderHighlight
                  class="project-file-name"
                  text={file.fileName}
                  keyword={projectFileKeyword.value}
                />

                {file.fileType !== 'folder' && (
                  <Iconfont
                    class={{ visible: fileIdList.value.includes(file.id) }}
                    name="close-med"
                    size={18}
                  />
                )}
              </div>
              {Boolean(file.childrenList?.length) &&
                [undefined, true].includes(expandedWrappers.value[file.id]) && (
                  <renderProjectFileList fileList={file.childrenList} level={level + 1} />
                )}
            </>
          )
        })
      ) : (
        <div class="file-empty">{i18n_t('plat_c.app_c.form.zanwujieguo')}</div>
      )}
    </div>
  )
}
const renderFilteredProjectFileList = (props: {
  fileList: (LocalFile & { displayedLabel: string })[]
}) => {
  const fileList = props.fileList ?? []

  return (
    <div
      class="filtered-project-file"
      onClick={onPreventEvent}
      onPointerdown={onPreventEvent}
      onPointerup={onPreventEvent}
    >
      {fileList.length ? (
        fileList.map((file) => {
          return (
            <div class="filtered-project-file-item" onClick={() => onProjectFileClicked(file)}>
              <div class="content">
                <FileIcon file={file} />

                <div>
                  <renderHighlight
                    class="project-file-name"
                    text={file.fileName}
                    keyword={projectFileKeyword.value}
                  />
                  <renderHighlight
                    class="project-file-folder"
                    text={file.displayedLabel}
                    keyword={projectFileKeyword.value}
                  />
                </div>
              </div>

              <Iconfont
                class={{ visible: fileIdList.value.includes(file.id) }}
                name="close-med"
                size={18}
              />
            </div>
          )
        })
      ) : (
        <div class="file-empty">{i18n_t('plat_c.app_c.form.zanwujieguo')}</div>
      )}
    </div>
  )
}

const getPasteString = (evt: ClipboardEvent) => {
  const text = (evt.clipboardData || window.clipboardData).getData('text')

  if (['', null, undefined].includes(text)) return null

  return text
}

const onProjectFilePaste = (evt: ClipboardEvent) => {
  const text = getPasteString(evt)

  const file = flatProjectFileLeafList.value.find((file) => {
    return file.url === text
  })

  const isExisted = curFileList.value.find((f) => {
    return f.url === file.url && f.fileToken === file.fileToken
  })

  if (file) {
    evt.preventDefault()

    if (isExisted) {
      return Message.warning('文件已存在')
    }

    onProjectFileClicked(file)
  }
}
const projectFilePopupVisible = ref(false)
const renderProjectFile = () => {
  // const fileList = projectFileTree.value
  const keyword = projectFileKeyword.value ?? ''
  const isFiltered = keyword !== ''

  return (
    <Popover
      popupVisible={actionPopupVisible.value}
      trigger="focus"
      position="bottom"
      contentClass="project-file-popover"
      update-at-scroll
      v-slots={{
        content: () => {
          return isFiltered ? (
            <renderFilteredProjectFileList fileList={filteredProjectFileList.value} />
          ) : (
            <renderProjectFileList fileList={projectFileTree.value} />
          )
        },
      }}
    >
      <div class={['project-file']}>
        <Input
          ref={(el: any) => (cloudFileRef.value = el)}
          v-model:modelValue={projectFileKeyword.value}
          placeholder={i18n_t('plat_c.app_c.form.sousuoxiangmuwendang')}
          onPaste={onProjectFilePaste}
          onFocus={() => (actionPopupVisible.value = true)}
          onBlur={() => (actionPopupVisible.value = false)}
        />
      </div>
    </Popover>
  )
}

const renderAction = () => {
  return (
    <div class="action-wrapper">
      <renderCurrentAction />
    </div>
  )
}

const renderHighlight = (props: { text: string; keyword: string }) => {
  const keyword = props.keyword ?? ''
  const reg = new RegExp(`(${keyword})`, 'gi')
  const text =
    keyword === '' ? props.text : props.text.replace(reg, `<span class="highlight">$1</span>`)

  // return <span innerHTML={text} />
  return <AutoTooltip className="overflow-esl" content={text} />
}
</script>

<style lang="scss" scoped>
.tool-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dee0e3;
  height: 34px;
  border-radius: 6px;
  .tool-trigger {
    flex: 1;
    cursor: pointer;
    height: 100%;
    padding: 0 10px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    :deep(.iconfont) {
      transform: scale(0.8333);
      transform-origin: center center;
      color: rgb(100, 106, 115);
      transition: all 200ms ease;
      &.expanded {
        transform: scale(0.8333) rotate(-180deg);
      }
    }
  }
  .action-wrapper {
    width: 280px;
    // text-align: right;
    border-left: 1px solid #dee0e3;
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    :deep() {
      .arco-input-wrapper {
        border: 0;
        outline: 1px solid #dee0e3;
        border-radius: 0 6px 6px 0;
        transition: all 300ms ease;
        &.arco-input-focus {
          border: 0;
          outline: 1px solid #3370ff;
        }
        .icon-close-med {
          cursor: pointer;
          display: none;
          color: #3272fe;
          &.visible {
            display: initial;
          }
        }
        .arco-input {
          height: 22px;
          padding: 4px 0;
        }
      }
      .local-file {
        padding: 0 10px;
        line-height: 32px;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
      .cloud-file,
      .project-file {
        width: 100%;
        .arco-input-wrapper {
          border: none;
        }
      }
      // .project-file {
      //   padding: 0 8px;
      //   height: 100%;
      //   line-height: 32px;
      //   border-radius: 0 6px 6px 0;
      //   &.focus {
      //     outline: 1px solid #3370ff;
      //   }
      // }
    }
  }
}
</style>
<style lang="scss">
.overflow-esl {
  width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.file-empty {
  width: 100%;
  // min-width: 280px;
  height: 32px;
  border-radius: 6px;
  line-height: 32px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #86909c;
}
.project-file-popover {
  padding: 4px;
  width: 350px;
  max-height: 256px;
  overflow: auto;
  .arco-popover-content {
    margin: 0;
  }
  > .project-file-list {
    // max-height: 256px;
    // overflow: auto;
  }
  .project-file-list {
    // padding: 0 0 0 8px;
    // max-height: 256px;
    // overflow: auto;
    .project-file-item {
      height: 32px;
      border-radius: 4px;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      padding: 0 8px;
      overflow: hidden;
      &:not(.expandable) {
        cursor: pointer;
        &:hover {
          background-color: #eceeee;
        }
      }
      .file-icon {
        width: 16px;
        height: 16px;
      }
      .project-file-name {
        // width: 211px;
        flex: 1;
        padding: 0 6px;
        color: #1f2329;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .highlight {
          color: #3370ff;
        }
      }
      .arrow-wrapper {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        margin-right: 2px;
        &:hover {
          background: rgba(31, 35, 41, 0.08);
        }
        .icon-icon_d_arrow {
          font-size: 12px;
          transform: scale(0.83) rotate(-90deg);
          transform-origin: center;
          transition: all 300ms ease;
          &.expanded {
            transform: scale(0.83);
          }
        }
      }
      .iconfont.icon-close-med {
        color: #3370ff;
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
      }
    }
  }
  .filtered-project-file {
    max-height: 256px;
    overflow: auto;
    .filtered-project-file-item {
      height: 48px;
      border-radius: 4px;
      transition: all ease 300ms;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      cursor: pointer;
      &:hover {
        background: #eceeee;
      }
      .content {
        width: 274px;
        display: flex;
        align-items: center;
        .file-icon {
          width: 24px;
          height: 24px;
        }
        & > div {
          padding-left: 10px;
          width: 270px;
          .project-file-name {
            height: 18px;
            line-height: 18px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #1f2329;
            font-weight: normal;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .project-file-folder {
            height: 18px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 18px;
            letter-spacing: 0px;
            color: #86909c;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .highlight {
          color: #326efc;
        }
      }
      .icon-close-med {
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
      }
    }
  }
}
.cloud-file-popover {
  padding: 4px;
  .arco-popover-content {
    margin: 0;
  }
  .cloud-file-list {
    max-height: 256px;
    overflow: auto;
    .cloud-file-item {
      height: 32px;
      border-radius: 4px;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      padding: 0 8px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        background-color: #eceeee;
      }
      .file-icon {
        width: 16px;
        height: 16px;
      }
      .cloud-file-name {
        width: 211px;
        padding: 0 6px;
        color: #1f2329;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .highlight {
          color: #3370ff;
        }
      }
      .iconfont {
        color: #3370ff;
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
      }
    }
  }
}
.tool-popover {
  padding: 4px;
  width: 160px;
  .action {
    display: flex;
    height: 32px;
    border-radius: 4px;
    transition: all ease 300ms;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    user-select: none;
    position: relative;
    &:hover {
      background: #eceeee;
    }
    &.action--active {
      color: #3272fe;
      .iconfont {
        visibility: visible;
      }
    }
    &-icon {
      height: 20px;
      width: 20px;
    }
    &-text {
      margin-left: 5px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #1f2329;
    }
    .iconfont {
      color: #3272fe;
      visibility: hidden;
      position: absolute;
      right: 7px;
    }
  }
  .split {
    width: 100%;
    height: 1px;
    position: relative;
    padding: 4px 0;
    &::after {
      position: absolute;
      content: '';
      left: -4px;
      right: 0;
      height: 1px;
      transform: translateY(0.5);
      top: 4px;
      width: calc(100% + 8px);
      background: #dbe0e3;
    }
  }
}
</style>
